import { createQueryKeys } from '@lukemorales/query-key-factory';

import type { PickBySchoolIdOrByLoginAdmin } from 'hooks/useSchool';
import type { Student } from 'services/external/PlurallAPI';
import type { AnalyticsUsersSchoolFilter } from 'pages/Analytics/AnalyticsRoute';
import { TeamDefinitionState } from 'pages/Run/components/TeamBuilding';

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

type StudentChallengesFilter = {
  codigosDesafios: string[];
  activity: {
    lessonCode: string;
    order: string;
  };
  activities: {
    lessonCode: string;
    amount: string;
  };
};

type PickSpecificActivityChallengeOrMultiple = RequireOnlyOne<StudentChallengesFilter>;

type LessonRatingsFilter = {
  codigoAula: string;
  complete: {
    codigoCurso: string;
    codigoModulo: string;
    codigoAula: string;
  };
};

type PickRatingsByLessonOrWithCompleteLessonModuleCode = RequireOnlyOne<LessonRatingsFilter>;

export const facilitatorKeys = createQueryKeys('facilitadores', {
  id: (login: string) => [login],
  exists: (login: string) => [login],
  getByAccessToken: (accessToken: string) => [{ accessToken }],
  turmas: (accessToken: string, schoolId: string | undefined) => {
    if (!schoolId) {
      return [{ accessToken }];
    }

    return [{ accessToken, schoolId }];
  },
  turmasAutoestudo: (accessToken: string) => [{ accessToken }],
  recuperarTurmasAutoestudoComDataInicio: (accessToken: string) => [{ accessToken }],
  avaliacoes: (login: string, codigoAula: string | undefined) => [{ login, codigoAula }],
  sso: (login: string) => [login],
});

export const courseKeys = createQueryKeys('cursos', {
  publico: (codigoCurso: string) => [codigoCurso],
  cursos: (codigosCursos: string[]) => [{ codigosCursos }],
  moduloPublico: (codigoCurso: string, codigoModulo: string) => [{ codigoCurso, codigoModulo }],
  ativos: ({
    tipoUser,
    accessToken,
    escolaId,
    userName,
  }: {
    tipoUser?: string | null;
    accessToken?: string;
    escolaId?: string | null;
    userName?: string;
  }) => {
    if (userName) {
      return [{ tipoUser, accessToken, escolaId, userName }];
    }

    return [{ tipoUser, accessToken, escolaId }];
  },
  gabaritoModulo: (codigoCurso: string, codigoModulo: string) => [{ codigoCurso, codigoModulo }],
  atividadesFundamentais: (codigoCurso: string, codigoModulo: string) => [codigoCurso, codigoModulo],
  planoAulas: (codigoCurso: string, codigoModulo: string, idForCourseLessonPlan: string) => [
    { codigoCurso, codigoModulo, idForCourseLessonPlan },
  ],
  nextPlanoAulas: (codigoCurso: string, codigoModulo: string, idForCourseLessonPlan: string) => [
    { codigoCurso, codigoModulo, idForCourseLessonPlan },
  ],
  nextPlanoAulasTurmas: (turmas: string[], idForCourseLessonPlan: string) => [{ turmas, idForCourseLessonPlan }],
  regrasAtividade: (codigoCurso: string, codigoModulo: string, codigoAula: string, ordem: number) => [
    { codigoCurso, codigoModulo, codigoAula, ordem },
  ],
  cursoModuloPermitidoPelaEscolaUsuario: (codigoCurso: string, codigoModulo: string, login: string) => [
    { codigoCurso, codigoModulo, login },
  ],
  aulasCursos: (codigosCursos: string[]) => [{ codigosCursos }],
  aulasCursosModulos: (codigosCursos: string[]) => [{ codigosCursos }],
  aulasModulo: (codigoCurso: string, moduloImprimivel: string) => [{ codigoCurso, moduloImprimivel }],
  divisaoAulasModulo: (codigoCurso: string, codigoModulo: string) => [{ codigoCurso, codigoModulo }],
  modulosCurso: (codigoCurso: string, escolaId: string) => [{ codigoCurso, escolaId }],
  cursosPermitidos: (escolaId: string) => [{ escolaId }],
  autoEstudosPermitidos: (accessToken: string, escolaId: string) => [{ accessToken, escolaId }],
});

export const projectKeys = createQueryKeys('projetos', {
  id: (idProjeto: string) => [idProjeto],
  getByIdSubmissao: (idSubmissao: string) => [idSubmissao],
  getByAccessToken: (accessToken: string) => [accessToken],
  getByTurma: (turmaId: string) => [turmaId],
  turma: (turmaId: string, praticaCodigo: string) => [{ turmaId, praticaCodigo }],
  submissaoFechada: (idSubmissao: string) => [{ idSubmissao }],
  template: (modulos: string[]) => [{ modulos }],
  alunos: (idProjeto: string) => [{ idProjeto }],
  urlEquipe: (idProjeto: string) => [{ idProjeto }],
  getProjetosPraticasAluno: (
    turmaId: AlunoEuObject['ultimaTurmaMatriculado'] | undefined,
    codigoCurso: AlunoEuObject['codigoCursoCorrente'] | undefined,
    codigoModulo: AlunoEuObject['codigoModuloCorrente'] | undefined,
    login: AlunoEuObject['login'] | undefined
  ) => [{ turmaId, codigoCurso, codigoModulo, login }],
});

export const lessonKeys = createQueryKeys('aulas', {
  id: (codigoAula: string) => [codigoAula],
  modulo: (codigosAulas: string[]) => [{ codigosAulas }],
  comentariosPlanoAula: (codigoAula: string) => [{ codigoAula }],
  avaliacaoMedia: (filter: PickRatingsByLessonOrWithCompleteLessonModuleCode) => [filter],
  templateEmailAulaNaoIniciada: (
    codigoCurso: string,
    codigoModulo: string,
    unidade: string,
    codigoAula: string,
    schoolId
  ) => [
    {
      schoolId,
      codigoCurso,
      codigoModulo,
      unidade,
      codigoAula,
    },
  ],
  ambientesAulasModulo: (aulas: string[]) => [{ aulas }],
  glossarioBilingue: (aulaCodigo: string) => [{ aulaCodigo }],
});

export const studentKeys = createQueryKeys('alunos', {
  id: (login: string) => [login],
  list: (logins: string[]) => [{ logins }],
  exists: (login: string) => [login],
  getByAccessToken: (accessToken: string) => [accessToken],
  getInfo: (accessToken: string) => [{ accessToken }],
  presencas: (login: string) => [login],
  presencasTurma: (params: { login: string; turma: string }) => [params],
  presencasTurmaModulo: (params: { login: string; turma: string; codigoCurso: string; codigoModulo: string }) => [
    params,
  ],
  progresso: (login: string) => [login],
  portifolio: (login: string) => [login],
  fotosEStatusLider: (logins: string[]) => [{ logins }],
  dadosNomesFotos: (logins: string[]) => [{ logins }],
  desafios: (filter: PickSpecificActivityChallengeOrMultiple) => [filter],
  aulas: (login: string) => [login],
  missoes: (loginCurso: string) => [loginCurso],
  missoesAlunos: (loginAlunosCurso: string[]) => [{ loginAlunosCurso }],
  provasAplicadas: (login: string) => [login],
  dadosMatricula: (logins: string[]) => [{ logins }],
  arquivos: (login: string) => [{ login }],
  arquivosCompartilhadosComInstrutor: (login: string) => [{ login }],
  equipe: (login: string) => [{ login }],
  turmaAtual: (accessToken: string) => [{ accessToken }],
  aulaAtual: (codigoAula: string) => [{ codigoAula }],
  simuladores: (login: string) => [{ login }],
  avaliacoesLicoes: (login: string) => [{ login }],
  desafiosAlunoTurma: (login: string, turmaId: string) => [{ login, turmaId }],
});

export const responsaveisKeys = createQueryKeys('responsaveis', {
  id: (login: string) => [login],
  escola: (params: { accessToken: string; escolaId: string }) => [params],
  alunosResponsavel: (responsavelId: string) => [{ responsavelId }],
  alunosComResponsaveis: (logins: string[]) => [{ logins }],
});

export const turmaKeys = createQueryKeys('turmas', {
  id: (turmaId: string) => [turmaId],
  turmasRegulares: (turmasIds: string[]) => [{ turmasIds }],
  alunos: (turmaId: string) => [{ turmaId }],
  dadosResponsaveis: (turmaId: string) => [{ turmaId }],
  presencas: (
    turmaId: string,
    codigoAula: string,
    aulaCodigoTexto: string,
    alunosTurma: TurmaDb['alunos'],
    accessToken: string
  ) => [
    {
      turmaId,
      codigoAula,
      aulaCodigoTexto,
      alunosTurma,
      accessToken,
    },
  ],
  presencasComFotos: (turmaId: string, codigoAula: string, aulaCodigoTexto: string) => [
    {
      turmaId,
      codigoAula,
      aulaCodigoTexto,
    },
  ],
  dadosPresencasTurmaAula: (params: { turmaId: string; aulaCodigo: string }) => [params],
  temPresencas: (turmaId: string) => [{ turmaId }],
  facilitadores: (listaFacilitadores: NonNullable<TurmaDb['instrutor']>) => [{ listaFacilitadores }],
  matriculas: (turmaId: string) => [{ turmaId }],
  presencasAula: (turmaId: string, codigoAula: string) => [{ turmaId, codigoAula }],
  presencasPorAlunoTurma: (aluno: string, turmaId: string) => [{ aluno, turmaId }],
  equipesSorteadasPratica: (turmaId: string, state: TeamDefinitionState) => [{ turmaId, state }],
  equipesSorteadasPraticaComTamanhoEquipe: (turmaId: string, tamanhoEquipe: number) => [{ turmaId, tamanhoEquipe }],
  equipesSorteadasPraticaComQtdEquipesDefinida: (turmaId: string, qtdEquipes: number) => [{ turmaId, qtdEquipes }],
});

export const plurallKeys = createQueryKeys('plurall', {
  profile: (accessToken: string) => [{ accessToken }],
  totalPages: (schoolIdPlurall: string) => [{ schoolIdPlurall }],
  students: (schoolIdPlurall: string, page: number, bearerToken: string) => [{ schoolIdPlurall, page, bearerToken }],
  classes: (classIdsPlurall: string[]) => [{ classIdsPlurall }],
  associationWithMindmakers: (classIdPlurall: string) => [{ classIdPlurall }],
  getPlurallClassIdFromMindmakersTurmaId: (turmaId: string) => [turmaId],
});

export const schoolKeys = createQueryKeys('escolas', {
  get: (filter: PickBySchoolIdOrByLoginAdmin) => [filter],
  getByAccessTokenAndSchoolId: (accessToken: string, schoolId: string) => [{ accessToken, schoolId }],
  list: null,
  instrutores: (schoolId: string) => [{ schoolId }],
  dadosResponsaveis: (schoolId: string) => [{ schoolId }],
  cursosDisponiveis: (schoolId) => [{ schoolId }],
  facilitadoresAtivos: (accessToken: string, schoolId: string) => [{ accessToken, schoolId }],
  alunosAtivos: (accessToken: string, schoolId: string) => [{ accessToken, schoolId }],
  alunos: (accessToken: string, schoolId: string) => [{ accessToken, schoolId }],
  alunosComStatus: (accessToken: string, schoolId: string, status: string) => [{ accessToken, schoolId, status }],
  listTurmas: (accessToken: string) => [{ accessToken }],
  listTurmasComSituacaoETipo: (params: { accessToken: string; schoolId: string; situacao: string; tipo: string }) => [
    params,
  ],
  listTurmasEmAndamento: (schoolId: string) => [{ schoolId }],
  listTurmasEmAndamentoRegularesAtivas: (schoolId: string) => [{ schoolId }],
  listTurmasEmAndamentoAutoEstudoAtivas: (schoolId: string) => [{ schoolId }],
  listTurmasEmAndamentoRegularesInativas: (schoolId: string) => [{ schoolId }],
  listTurmasEmAndamentoAutoEstudoInativas: (schoolId: string) => [{ schoolId }],
  nomesEscolas: (schoolIds: string[]) => [schoolIds],
  chaveChatGPT: (schoolId: string) => [schoolId],
  escolasPlurall: (idsPlurallEscolas: string[]) => [{ idsPlurallEscolas }],
  idPlurallEscola: (idEscola: string) => [idEscola],
  possuiSomenteCursosMMCode: (idEscola: string) => [idEscola],
  analiseIAEscola: (params: Pick<AnalyticsUsersSchoolFilter, 'currentSchool' | 'dateInterval'>) => [params],
});

export const noticesKeys = createQueryKeys('avisos', {
  list: (params: Pick<FirstcodeUserProfile, 'tipo' | 'username'>) => [params],
  getForFacilitator: (user: string) => [{ user }],
  getOne: (noticeId: string) => [{ noticeId }],
  getVistos: (noticeId: string) => [{ noticeId }],
});

export const requestsKeys = createQueryKeys('requerimentos', {
  list: (user: string) => [{ user }],
});

export const announcementsKeys = createQueryKeys('anuncios', {
  list: (params: Pick<FirstcodeUserProfile, 'tipo' | 'username'>) => [params],
  getForUser: (user: string) => [{ user }],
  getOne: (noticeId: string) => [{ noticeId }],
  getVistos: (noticeId: string) => [{ noticeId }],
});

export const practiceKeys = createQueryKeys('inventotecas', {
  id: (praticaCodigo: string) => [praticaCodigo],
  list: null,
  basesConhecimento: null,
});

export const userKeys = createQueryKeys('users', {
  id: (login: string) => [login],
  exists: (login) => [login],
  list: null,
  registeredPlurallStudents: (schoolId: string, loginAdminSchool: string, students: Array<Student>) => [
    { schoolId, loginAdminSchool, students },
  ],
  dadosBasicos: (login: string) => [{ login }],
  nomesUsuariosEscolas: (logins: string[], schoolIds?: string[]) => [{ logins, schoolIds: schoolIds ?? [] }],
  getUsuariosComNomeEscola: (logins: string[]) => [{ logins }],
});

export const knowledgebasesKey = createQueryKeys('knowledgebases', {
  id: (login: string) => [login],
  list: null,
  dadosModuloGerado: (codigoCurso: string, codigoModulo: string) => [{ codigoCurso, codigoModulo }],

  topicosBases: null,
});
export const electionKeys = createQueryKeys('eleicoes', {
  id: (eleicaoId: string) => [eleicaoId],
  abertas: null,
});

export const linkUrlExecucaoKey = createQueryKeys('linkUrlExecucao', {
  byLessonActivity: (
    codigoLicao: string,
    itemRoteiroOrdem: number | undefined,
    urlRegra: string | undefined,
    urlAtividadeRoteiro: string | undefined,
    tokenCodeOrg: string | null | undefined
  ) => [
    {
      codigoLicao,
      itemRoteiroOrdem,
      urlRegra,
      urlAtividadeRoteiro,
      tokenCodeOrg,
    },
  ],
  byLessonActivityWithRules: (
    codigoLicao: string,
    itemRoteiroOrdem: number | undefined,
    regras: Array<RegraatividadeTypeDb> | null | undefined
  ) => [
    {
      codigoLicao,
      itemRoteiroOrdem,
      regras,
    },
  ],
});

export const provasKeys = createQueryKeys('provasList', {
  porEscolaEDatas: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool'>) => [filter],
});

export const testKeys = createQueryKeys('provas', {
  porEscola: (schoolId: string) => [{ schoolId }],
  aplicadas: (login: string, modelo: string, versao: string) => [
    {
      login,
      modelo,
      versao,
    },
  ],
  questoes: (questionsIds: string[]) => [{ questionsIds }],
  instanciasOnline: (modelo: string, versao: string) => [
    {
      modelo,
      versao,
    },
  ],
});

export const topicosCurricularesKeys = createQueryKeys('topicos', {
  list: null,
  aulas: null,
  planosDeAula: null,
});

export const analyticsKeys = createQueryKeys('analytics', {
  totalUsuarios: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  totalUsuariosDias: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  totalUsuariosUltimos30min: null,
  rankingEscolasPorEmailsEnviados: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  emailsEnviadosPorDia: (
    filter: Partial<
      Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentAdvisor'>
    >
  ) => [filter],
  visualizacoesPaginas: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'> & { dimensionFilter: boolean }) => [
    filter,
  ],
  visualizacoesPaginasHourOfCode: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
});

export const bigQueryKeys = createQueryKeys('BigQuery', {
  getTempoAutoestudoPeriodo: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'currentAdvisor'
    >
  ) => [filter],
  getAutoestudosDetalhes: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'currentAdvisor'
    >
  ) => [filter],
  getAutoestudosDetalhesEspecificos: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'currentAdvisor'
    > & {
      autoestudo?: string | null | undefined;
      status?: string | null | undefined;
    }
  ) => [filter],
  getAutoestudosDetalhesEspecificosPorFacilitador: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'currentAdvisor'
    > & {
      autoestudo?: string | null | undefined;
      status?: string | null | undefined;
    }
  ) => [filter],
  getAutoEstudoSegmentos: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'currentAdvisor'
    >
  ) => [filter],
  ocorrenciasPorData: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  getOcorrencias: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ) => [filter],
  getOcorrenciasNaoFechadas: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'currentAdvisor'
    >
  ) => [filter],
  getOcorrenciasAbertasFiltradas: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ) => [filter],
  getOcorrenciasNaoFinalizadasFiltradas: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ) => [filter],
  emailsSuporte: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  finalizacoesAulas: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    > & {
      turmaTeste?: boolean | null | undefined;
    }
  ) => [filter],
  finalizacoesRanking: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  alunosEngajadosRanking: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  desafiosRanking: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>) => [
    filter,
  ],
  acessosRanking: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>) => [
    filter,
  ],
  acessosBlocklyRanking: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ) => [filter],
  acessosCodeRanking: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ) => [filter],
  acessosAlunosModulo: (
    name: string,
    filter: Pick<AnalyticsUsersSchoolFilter, 'specificDate' | 'currentSchool' | 'filteredSchools'>
  ) => [name, filter],
  acessosFacilitadoresDia: (
    name: string,
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'specificDate' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ) => [name, filter],
  acessosAlunosDia: (
    name: string,
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'specificDate' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ) => [name, filter],
  finalizacoesAulasDia: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'specificDate' | 'currentSchool' | 'filteredSchools' | 'currentAdvisor' | 'currentFacilitator'
    >
  ) => [filter],
  acessosPaginas: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'videoType'
    >
  ) => [filter],
  acessosPaginasCompleto: (
    name: string,
    filter: Omit<AnalyticsUsersSchoolFilter, 'specificDate' | 'currentAdvisor' | 'videoType'>
  ) => [name, filter],
  acessosPaginasHourOfCode: (filter: Omit<AnalyticsUsersSchoolFilter, 'specificDate'>) => [filter],
  acessosVideos: (
    type: 'expositiva' | 'assessoria' | 'missão',
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator' | 'videoType'
    >
  ) => [type, filter],
  acessosVideosDia: (
    type: 'todos' | 'expositiva' | 'assessoria' | 'missão',
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'specificDate' | 'currentSchool' | 'filteredSchools' | 'currentAdvisor' | 'currentFacilitator' | 'videoType'
    > & { dateInterval?: AnalyticsUsersSchoolFilter['dateInterval'] }
  ) => [type, filter],
  emailsComRemetentesSemGrupo: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  emailsComDestinatariosInvalidos: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  emailsInvalidos: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>) => [
    filter,
  ],
  emailsRecebidosPorDiaSendgrid: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  emailsLinksClicados: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  rankingEscolasPorEmailsEnviados: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  mediaAvaliacoes: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  provasCategorias: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>
  ) => [filter],
  provasQuestoes: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>) => [
    filter,
  ],
  mediaAvaliacoesPorFacilitadorLicao: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  acessosUnicosAlunosPlurall: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>> & {
      alunoTeste?: boolean | null | undefined;
    }
  ) => [filter],
  acessosUnicosAlunosDireto: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>> & {
      alunoTeste?: boolean | null | undefined;
    }
  ) => [filter],
  acessosUnicosPeriodoAlunosPlurall: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>> & {
      alunoTeste?: boolean | null | undefined;
    }
  ) => [filter],
  acessosUnicosPeriodoAlunosDireto: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>> & {
      alunoTeste?: boolean | null | undefined;
    }
  ) => [filter],
  acessosUnicosFacilitadoresPlurall: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>>
  ) => [filter],
  acessosUnicosFacilitadoresDireto: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>>
  ) => [filter],
  acessosUnicosPeriodoFacilitadoresPlurall: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>>
  ) => [filter],
  acessosUnicosPeriodoFacilitadoresDireto: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>>
  ) => [filter],
  acessosUnicosAlunosModuloTeens: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>> & {
      alunoTeste?: boolean | null | undefined;
    }
  ) => [filter],
  acessosUnicosAlunosModuloKids: (
    filter: Partial<Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools'>> & {
      alunoTeste?: boolean | null | undefined;
    }
  ) => [filter],
  totalDesafiosAlunos: (
    filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentAdvisor'>
  ) => [filter],
  mediasDesafiosAlunos: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  totalDesafiosFacilitadores: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentAdvisor' | 'currentFacilitator'
    >
  ) => [filter],
  mediasDesafiosFacilitadores: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  acessosPaginasEscola: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    >
  ) => [filter],
  finalizacoesAulasEscola: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    >
  ) => [filter],
  avaliacoesLicoesAluno: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  detailedAvaliacoesLicoesAluno: (
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'filteredSchools' | 'currentFacilitator'
    >
  ) => [filter],
  acessosVideosEscola: (
    type: 'expositiva' | 'assessoria' | 'missão',
    filter: Pick<
      AnalyticsUsersSchoolFilter,
      'dateInterval' | 'currentSchool' | 'currentFacilitator' | 'filteredSchools'
    >
  ) => [type, filter],
  ritmoAulas: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
  fechamentoAulas: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'> & { modulo: string }) => [filter],
  licaoTopicosCurriculares: null,
  totalHorasAutoestudo: (filter: Pick<AnalyticsUsersSchoolFilter, 'dateInterval'>) => [filter],
});

export const selfieKeys = createQueryKeys('selfies', {
  list: null,
  products: null,
  pesquisas: null,
  pesquisasSimuladasDe: (login: string) => [login],
  pesquisaById: (pesquisaId: string) => [pesquisaId],
  perguntaById: (perguntaId: string) => [perguntaId],
  perguntasDominio: (perguntasIds: string[]) => [{ perguntasIds }],
  perguntasPublicoAlvo: (publicoAlvo: string) => [publicoAlvo],
  impactosProduto: (codigoProduto: string) => [codigoProduto],
});

export const conversationKeys = createQueryKeys('conversations', {
  getUserConversationBySubject: (filter: { user: string; subject: string; specificDivisionFromSubject?: string }) => [
    filter,
  ],
  chatbotResponse: null,
});
